<template>
  <div
    class="
      h-screen
      flex
      w-full
      bg-img
      vx-row
      no-gutter
      justify-center
      items-center
    "
  >
    <div
      class="vx-col sm:w-1/2 md:w-1/2 lg:w-2/5 m-4"
      style="transform: translateY(-50%)"
    >
      <vx-card>
        <div class="vx-card__title mb-6">
          <h2 class="text-center">Order Completed!</h2>
          <p class="text-center">Thank you, your order has been placed.</p>
          <br />
          <p class="text-center">Order # - {{ orderNumber }}</p>
          <div class="flex flex-wrap justify-center">
            <vs-button
              icon-pack="feather"
              icon="icon-eye"
              @click="viewOrderRoute"
              >View Order</vs-button
            >
          </div>
          <p class="text-center mt-4">
            This page will close in {{ seconds }} seconds.....
          </p>
        </div>
      </vx-card>
      <ShopFeedback @onSubmit="handleSubmitFeedback" :active="feedBackActive" :step="feedbackStep" @onClose="handleClose"/>
    </div>
  </div>
</template>

<script>
import ShopFeedback from './feedbackSurvey.vue'
import { mapActions } from "vuex";

export default {
  name: "ClinicOrderComplete",
  inject: ['getRoleRouteName'],
  components: {
    ShopFeedback
  },
  data() {
    return {
      orderId: "",
      orderNumber: "",
      seconds: 10,
      interval: null,
      feedBackActive: false,
      userId: "",
      feedbackStep: 1,
    };
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.orderNumber = this.$route.query.orderNumber;
    this.feedBackActive = true
    this.userId =JSON.parse(localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`
        ))._id
  },
  methods: {
    ...mapActions("ecommerce", ["saveFeedback"]),
    async handleSubmitFeedback(val) {
      try {
        if(val.reaction !== '' && val.remarks !== ''){
          const payload = {...val}
          payload.userId = this.userId
          payload.metadata = {
            orderId: this.orderId,
            clinicId: sessionStorage.getItem("doctorClinicSelectedClinic"),
          }
          payload.slug = "shop-orders-feedback"
          await this.saveFeedback(payload)

        }
        this.feedbackStep = 2
        
      } catch (error) {
        console.error(error)
      }
    },
    handleClose() {
      this.feedBackActive = false;
      this.counter();
    },
    counter() {
      const self = this;
      this.interval = setInterval(function () {
        if (self.seconds == 0) {
          clearInterval(self.interval);
          self.$router.push({
              name: self.getRoleRouteName("OrderDetail"),
              params: { orderId: self.orderId },
            })
            .catch((e) => console.warn(e));

        } else console.log("Currently at " + self.seconds--);
      }, 1000);
    },
    viewOrderRoute() {
      clearInterval(this.interval);
      this.$router
        .push({ name: this.getRoleRouteName("OrderDetail"), params: { orderId: this.orderId } })
        .catch(() => {});
    }
  },
  destroyed() { 
    clearInterval(this.interval)
  }
};
</script>

<style scoped></style>
