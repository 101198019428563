<template>
    <vs-popup title="Feedback form" :active.sync="active">
        <div class=" p-16" v-if="step===1">
            <div class=" mb-10">
                <h2>How was your ordering experience?</h2>
            </div>
            <div class=" mb-8" >
                <div class=" mb-3">
                    <span class=" font-medium text-base flex">How did you feel about your ordering experience? <span class="text-validation">*</span></span>
                </div>
                <div class=" flex items-center justify-center">
                    <div 
                        @click="onClickReaction('bad')" 
                        class=" mx-2 cursor-pointer  flex items-center flex-col hover:shadow-lg content-center px-3 pb-2"
                        style=" width: 90px ;"
                        :class="`${reaction === 'bad' ? 'shadow-md': ''}`"
                    >
                        <div class="text-5xl ">🙁</div>
                        <div class=" text-sm">Unhappy</div>
                    </div>
                    <div 
                        @click="onClickReaction('neutral')" 
                        class=" mx-2 cursor-pointer  flex items-center flex-col content-center hover:shadow-lg px-3 pb-2"
                        style=" width: 90px ;"
                        :class="`${reaction === 'neutral' ? 'shadow-md': ''}`"
                    >
                        <div class="text-5xl ">😐</div>
                        <div class=" text-sm">Neutral</div>
                    </div>
                    <div 
                        @click="onClickReaction('good')" 
                        class=" mx-2 cursor-pointer  flex items-center flex-col content-center hover:shadow-lg px-3 pb-2"
                        style=" width: 90px ;"
                        :class="`${reaction === 'good' ? 'shadow-md': ''}`"
                    >
                        <div class="text-5xl ">😍</div>
                        <div class=" text-sm">Satisfied</div>
                    </div>
                </div>
                <div v-if="isInvalid" class="text-validation text-sm">
                    Please choose a reaction before submitting your feedback.
                    <!-- <span>
                    </span> -->
                </div>
            </div>

            <div>
                <div class=" h-24 sm:h-12 flex items-end">
                    <div v-if="reaction !== ''" class=" mb-3">
                        <span v-if="reaction === 'bad'"  class=" font-medium text-base">Can you tell us more about your experience, so we can get it right the next time?</span>
                        <span v-if="reaction === 'neutral'" class=" font-medium text-base">Can you tell us more about your experience, so we can do even better next time?</span>
                        <span v-if="reaction === 'good'" class=" font-medium text-base">Thank you! Can you tell us why you scored us so highly?</span>
                    </div>
                </div>
                <div>
                    <vs-textarea v-model="remarks" />
                </div>
            </div>
            <div>
                <vs-button @click="handleSubmit">Submit</vs-button>
            </div>
        </div>
        <div v-if="step===2">
            <div class=" py-10">
                <div class=" flex justify-center mb-8">
                    <img style="width: 150px;" src="../../../assets/images/feedbackimage.svg"/>
                </div>
                <div class=" flex justify-center  mb-8">
                    <h1>Thank you so much</h1>
                </div>
                <div class=" flex justify-center">
                    <span>Your feedback means the world to us.</span>
                </div>
            </div>
        </div>
    </vs-popup>

</template>

<script> 

export default {
    name: "ShopFeedback",
    props: {
        active : { type: Boolean, default: false, },
        step : { type: Number, default: 1, },
    },
    data() {
      return{
          // active: false,
          reaction: "",
          remarks: "",
          isInvalid: false
      }
    },
    methods: {
        onClickReaction(reaction){
            this.isInvalid = false
            this.reaction = reaction
        },
        handleSubmit(){
            if(this.reaction!==''){

                const val = {
                    reaction: this.reaction,
                    remarks: this.remarks
                }
                this.$emit('onSubmit', val)
            }
            else {
                this.isInvalid = true
            }
        }
      
    },
    watch: {
        "active": function(val) {
            if(!val){
                this.$emit('onClose')
            }
        }
    },
};

</script>

<style scoped>
.text-validation{
    padding: 2px 4px;
    padding-bottom: 4px;
    display: block;
    color: rgba(var(--vs-danger), 1) !important;
}
</style>
  